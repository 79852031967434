@import "/src/data/styles.css";

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background-color: #383636;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgb(0, 0, 0);
}

/* Sticky Footer Technique */
.page-content {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f7f7f7;
    position: relative;
}

.page-content:before,
.page-content:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: calc((100% - 1200px) / 2);
    background-color: #65A7B3;
    border: 1px solid #f4f4f5;
}

.page-content:before {
    left: 0;
    border-right: none; /* Remove the right border on the left pseudo-element */
    z-index: 1;
}

.page-content:after {
    right: 0;
    border-left: none; /* Remove the left border on the right pseudo-element */
    z-index: 1;
}

.content-wrapper {
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: auto; /* Push content to the top */
}

.title {
    color: var(--primary-color);
    font-family: var(--secondary-font);
    font-size: 45px;
    font-weight: 700;
    width: 70%;
}

.subtitle {
    padding-top: 10px;
    color: var(--secondary-color);
    font-size: 16px;
    line-height: 28px;
    width: 70%;
    margin-top: 25px;
}

.midtitle {
    color: var(--primary-color);
    font-family: var(--secondary-font);
    font-weight: 700;

    padding-top: 10px;
    color: var(--secondary-color);
    font-size: 24px;
    line-height: 28px;
    width: 70%;
    margin-top: 20px;
}

.page-footer {
    position: relative;
    margin-top: auto;
}

.page-footer::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border-top: 2px solid var(--tertiary-color);
}

@media (max-width: 1270px) {
    .content-wrapper {
        max-width: 90%;
        padding-left: 10%;
        padding-right: 10%;
    }
}

@media (max-width: 1024px) {
    .content-wrapper {
        max-width: 980px;
    }
}

@media (max-width: 800px) {
    .content-wrapper {
        max-width: 90%;
        margin: 0 auto;
        padding: 0 10px;
    }

    .title {
        width: 100%;
    }

    .subtitle {
        width: 100%;
    }
}
