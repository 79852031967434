
.experience-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-top: 120px;
}

.experience-section {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	padding-top: 30px;
}

.job-experience {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 30px;
	border-bottom: 1px solid var(--secondary-color);
	padding-bottom: 20px;
}

.job-experience h3 {
	margin-bottom: 5px;
	font-size: 1.5rem;
	color: var(--primary-color);
}

.job-experience p {
	font-size: 1rem;
	color: var(--text-color);
	margin-bottom: 10px;
}

.job-experience ul {
	list-style: disc;
	margin-left: 20px;
	color: var(--text-color);
}

.job-experience li {
	font-size: 1rem;
	margin-bottom: 5px;
}

/* For the experience logo (picture of your face) */
.experience-logo-container {
	display: flex;
	justify-content: left; /* Align it to the left */
	padding-top: 25px;
}

.experience-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

/* For the company cover photo */
.cover-photo {
	width: 8%; /* Adjust the size here */
	height: auto;
	margin-right: 15px;
	border-radius: 10px; /* Adjust as needed */
}

.experience-socials {
	display: flex;
	justify-content: flex-start; /* Left-align the social icons */
	align-items: center;
	padding-top: 30px;
}


.experience-social-icon {
	padding-right: 20px;
	font-size: 30px;
	transition: color 0.2s ease-in-out;
	color: var(--secondary-color);
}

.experience-social-icon:hover {
	color: var(--primary-color);
}

/* Responsive Design */
@media (max-width: 1024px) {
	.experience-section {
		flex-direction: column;
		padding-top: 20px;
	}

	.job-experience {
		width: 100%;
		margin-bottom: 20px;
		padding-bottom: 15px;
	}
}



/* Add this to your experience.css or wherever appropriate */

.visit-page-btn {
    display: inline-block;
    padding: 8px 16px;
    margin-top: 10px;
    background-color: transparent;
    border: 1px solid var(--primary-color); /* Match the project button border */
    color: var(--primary-color); /* Use the same color as the project button */
    border-radius: 20px;
    text-decoration: none;
    font-size: 14px;
    transition: all 0.2s ease;
    cursor: pointer;
    text-align: center;
}

.visit-page-btn:hover {
    background-color: var(--primary-color);
    color: white; /* Text turns white on hover */
}

.visit-page-btn:active {
    transform: scale(0.98); /* Slight button press effect */
}
