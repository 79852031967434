@import "../../../data/styles.css";

.project {
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
	height: 100%;
	background-color: hsl(0, 0%, 95%);
	position: relative;
}

.project a {
	text-decoration: none;
}

.project:hover {
	background: hsl(0, 3%, 94%);
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
	box-shadow: 20px;
}

.project:hover .project-link {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.project-container {
	
	padding: 30px;
	padding-bottom: 0px;
}

.description-container {
	
	padding-bottom: 5px;
	
}


.project-logo {
	width: 30px;
}

.project-logo img {
	width: 100%;
}

.project-star {
	position: absolute;
	top: 25px; /* Adjust the top position as needed */
	right: 20px; /* Adjust the right position as needed */
	width: 30px;
}

.project-star img {
	width: 80%;
}

.project-title {
	font-family: var(--secondary-font);
	color: var(--primary-color);
	font-size: 16px;
	padding-top: 15px;
	margin-bottom: 1em;
	font-weight: 700;
}

.project {
	position: relative;
}

.project-description {
    color: var(--secondary-color);
    border: 10px ;
    border-color: black;
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Display an ellipsis (...) to indicate truncated text */
    max-width: 100%; /* Set maximum width to 100% */
    max-height: 120px; /* Set maximum height before text truncates */
    white-space: normal; /* Allow text to wrap within the container */
}

.project-link {
	position: absolute;
    bottom: 0; /* Align to the bottom of the .project-container */
    left: 0; /* Align to the left of the .project-container */
    width: 100%; /* Take up the full width of the .project-container */
    display: flex;
    align-items: center;
    color: var(--secondary-color);
    font-size: 12px;
    padding-top: 5px;
	padding-bottom: 8%;
}

.project-link-icon {
    position: absolute;
    left: 5px; /* Adjust this value as needed */
    font-size: 13px;
	padding-left: 10%;
}

.project-link-text {
    padding-left: 22%; /* Adjust this value as needed */
    font-weight: 700;
}


@media (max-width: 600px) {
	.project {
		height: 300px;
	}
}
